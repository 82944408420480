import React from "react";

const CaptionWithLink = ({ caption, courtesy, url }) => {
  return (
    <span>
      {caption}<br />(image courtesy of{" "}
      <a href={url} target="_new">
        {courtesy}
      </a>
      )
    </span>
  );
};

export default CaptionWithLink;
