import React from "react";

const AddressPotter = () => {
  return (
    <>
      <h3>
        Potter County PA CareerLink<sup>&reg;</sup>
      </h3>
      <div>
        <p>
          279 Route 6 West
          <br />
          Coudersport, PA 16915
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
    </>
  );
};

export default AddressPotter;
