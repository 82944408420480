import React from "react";
import { Link } from "gatsby";
import StatBox from "../../components/stat-box";
import PagePhoto from "../../components/page-photo";
import { graphql } from "gatsby";
import CaptionWithLink from "../../components/caption-with-link";
import CareerLinkBox from "../../components/career-link-box";
import HeroPage from "../../components/hero-page";
import AddressPotter from "../../components/address-potter";
import StatBoxes from "../../components/stat-boxes";
import ServiceList from "../../components/service-list";
import { postSecondary } from "../../data/post-secondary";
import { education } from "../../data/education";
import { hospitals } from "../../data/hospitals";
import { chambers } from "../../data/chambers";
import ExplorePhotos from "../../components/explore-photos";
import Seo from "../../components/seo";

const Potter = ({ data }) => {
  const photos = data.allMdx.nodes;

  return (
    <>
      <Seo
        title="Potter County, North Central Pennsylvania"
        description="Star-gazers have some of the best dark skies in the mid-Atlantic region here in Potter County, at the Cherry Spring State Park"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Potter County"
        caption="Night Sky in Cherry Springs"
        position="10%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>View planets and nebulae right in your own back yard.</h2>

            <p>
              Star-gazers have some of the best dark skies in the mid-Atlantic
              region here in Potter County, at the Cherry Spring State Park.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Most of the country is affected by <i>light pollution</i> that
              blocks view of the night sky. But the skies above us can display
              10,000 or more stars with no glow.
            </p>

            <p>The county is also home to the Pennsylvania Lumber Museum.</p>
            <p>
              The bountiful forests of magnificent white pine, hemlock and
              hardwoods serve as this county’s main attraction and economic
              lifeblood.
            </p>

            <p>
              In addition to farming and lumbering, other components of the
              county's economic foundation are: manufacturing facilities, small
              businesses, tourism and recreation.
            </p>
          </div>
        </section>

        <StatBoxes>
          <StatBox number="16,937" text="Total population" />
          <StatBox number="42,819" text="Per capita income" />
          <StatBox number="Coudersport" text="County seat" />
        </StatBoxes>
      </main>

      <div className="county-employers">
        <section>
          <div className="employers-table-container">
            <h2>Top employers in Potter County</h2>

            <table className="employers">
              <tr>
                <th>Employer</th>
                <th>Industry</th>
              </tr>

              <tr>
                <td>UPMC Cole</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Morris Compressors Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Cole Care Inc</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Zito Media</td>
                <td>Communications</td>
              </tr>

              <tr>
                <td>State Government</td>
                <td></td>
              </tr>

              <tr>
                <td>Coudersport Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Level 3 Communications LLC</td>
                <td>Communications</td>
              </tr>

              <tr>
                <td>Sweden Valley Manor</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Northern Potter School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Gas Field Specialists Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>
            </table>
          </div>

          <div className="employers-photos">
            <PagePhoto
              image={data.employersImage1.sharp.image}
              caption="UPMC Cole, Potter County"
            />
            <PagePhoto
              image={data.employersImage2.sharp.image}
              caption={
                <CaptionWithLink
                  caption="Hardwood debarker"
                  courtesy="AHUG"
                  url="http://ahug.com"
                />
              }
            />
          </div>
        </section>
      </div>

      <main>
        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="UPMC Cole, Potter County"
          address=<AddressPotter />
        />

        <section>
          <h2>County Services</h2>

          <ServiceList
            title="Education"
            image={data.educationImage.sharp.fluid}
            position="40%"
            county="Potter"
            data={education}
            cols={2}
            headingText="School"
          />

          <ServiceList
            title="Post Secondary Education"
            image={data.postSecondary.sharp.fluid}
            position="42%"
            county="Potter"
            data={postSecondary}
            cols={2}
            headingText="School"
          />

          <div className="services-body">
            <div className="services-col">
              <ServiceList
                title="Hospitals"
                image={data.medicalImage.sharp.fluid}
                position="30%"
                county="Potter"
                data={hospitals}
                headingText="Facility"
              />
            </div>
            <div className="services-col">
              <ServiceList
                title="Chambers of Commerce"
                image={data.businessImage.sharp.fluid}
                position="55%"
                county="Potter"
                data={chambers}
                headingText="Service"
              />
            </div>
          </div>
        </section>

        <ExplorePhotos photos={photos} county="Potter" />
      </main>
    </>
  );
};

export const data = graphql`
  query {
    educationImage: file(
      relativePath: { eq: "neonbrand-zFSo6bnZJTw-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    postSecondary: file(
      relativePath: { eq: "clay-banks-GX8KBbVmC6c-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(relativePath: { eq: "night-sky.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "night-sky.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

    employersImage1: file(relativePath: { eq: "IMG_2998.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage2: file(
      relativePath: { eq: "10968_KaneHardwood_ab_009.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    careerImage: file(
      relativePath: {
        eq: "120198198_3729203207098794_5382515686103435997_ob.jpg"
      }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    medicalImage: file(
      relativePath: { eq: "olga-guryanova-tMFeatBSS4s-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    businessImage: file(
      relativePath: { eq: "arlington-research-nFLmPAf9dVc-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    # slideshow photos
    allMdx(filter: { frontmatter: { county: { eq: "potter" } } }) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

export default Potter;
